import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import ProjectImage from '../components/molecules/ProjectImage'
import styles from './index.module.scss'
import blogStyles from './blog.module.scss'
import BlogPost from '../components/molecules/BlogPost'

const Home = ({ data }) => {
  const projects = data.allProjectsYaml.edges
  const posts = data.posts.edges

  return (
    <div>
      <div className={styles.section__colored}>
        <h1 className={styles.section__title}>Featured Projects</h1>
        <div className={styles.projects}>
          {projects.map(({ node }) => {
            const { slug, title, img } = node

            return (
              <article className={styles.project} key={slug}>
                <Link to={'/projects/' + slug}>
                  <h1 className={styles.title}>{title}</h1>
                  <ProjectImage fluid={img.childImageSharp.fluid} alt={title}/>
                </Link>
              </article>
            )
          })}
        </div>
      </div>

      <div className={styles.section}>
        <h1 className={styles.section__title}>Recent posts</h1>
        <div className={blogStyles.posts}>
          {posts.map(({ node }) => {
            let { frontmatter } = node
            const { slug, title, cover, excerpt } = frontmatter

            return (
              <article className={blogStyles.post} key={slug}>
                <Link to={'/posts/' + slug}>
                  <BlogPost
                    fluid={cover.childImageSharp.fluid}
                    alt={title}
                    description={excerpt}
                  />
                </Link>
              </article>
            )
          })}
        </div>
      </div>
    </div>
  )
}

Home.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object
}

export default Home

export const IndexQuery = graphql`
  query {
    allProjectsYaml {
      edges {
        node {
          title
          slug
          img {
            childImageSharp {
              fluid(maxWidth: 980, quality: 85) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

    posts: allMarkdownRemark {
      edges {
        node {
          frontmatter {
            slug
            cover {
              childImageSharp {
                fluid(maxWidth: 980, quality: 85) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            title
            excerpt
          }
        }
      }
    }
  }
`
